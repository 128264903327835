<script>
  document.body.classList.add("bg-default");
</script>

<div>
  <!-- Header -->
  <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
    <div class="container">
      <div class="header-body text-center mb-7">
        <div class="justify-content-center row">
          <div class="px-5 col-xl-5 col-lg-6 col-md-6">
            <h1 class="text-light">Ooops!</h1>
            <p class="text-lead text-white">
              Page not found. Don't worry though, we have plenty of other pages
              to explore
            </p>

            <a href="/dashboard" class="font-weight-bold text-white mt-5">
              Back to dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </div>
  <!-- Page content -->
  <section class="py-6">
    <div class="container">
      <div class="row align-items-center row-grid">
        <div class="col-md-6">
          <img src="../img/theme/landing-2.png" alt="image" class="img-fluid" />
        </div>
        <div class="col-md-6">
          <div class="pr-md-5">
            <h1 class="text-light">Explore pages</h1>
            <p class="text-light">
              If you want to get inspiration or just show something directly to
              your clients, you can jump start your development with our
              pre-built example pages.
            </p>
            <a
              href="/dashboard/pages/user"
              class="font-weight-bold text-warning mt-5">
              Explore pages
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
