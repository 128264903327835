import Login from './views/public/login.svelte'
import PublicIndex from './views/public/index.svelte'
import PublicLayout from './views/public/layout.svelte'
import Lock from "./views/public/lock.svelte";
import Register from "./views/public/register.svelte";
import Pricing from "./views/public/pricing.svelte";

// Dashboard
import DashboardLayout from './views/Dashboard/layout.svelte';
import DashboardIndex from './views/Dashboard/index.svelte';
import Charts from './views/Dashboard/charts.svelte';
import Widgets from './views/Dashboard/widgets.svelte';
import Alternative from './views/Dashboard/AlternativeDashboard.svelte';
// pages
import TimeLinePage from './views/Dashboard/pages/timeLinePage.svelte';
import UserProfile from './views/Dashboard/pages/userProfile.svelte';
//components
import Buttons from "./views/Dashboard/components/buttons.svelte";
import Cards from "./views/Dashboard/components/cards.svelte";
import GridSystem from "./views/Dashboard/components/gridSystem.svelte";
import Icons from "./views/Dashboard/components/icons.svelte";
import Notifications from "./views/Dashboard/components/notifications.svelte";
import Typography from "./views/Dashboard/components/typography.svelte";
//forms
import FormElements from "./views/Dashboard/Forms/formElements.svelte";
import FormComponents from "./views/Dashboard/Forms/formComponents.svelte";
import FormValidation from "./views/Dashboard/Forms/formValidation.svelte";
//tables
import RegularTables from "./views/Dashboard/Tables/regularTables.svelte";
import PaginatedTables from "./views/Dashboard/Tables/paginatedTables.svelte";
import SortableTables from "./views/Dashboard/Tables/sortableTables.svelte";
//maps
import GoogleMaps from "./views/Dashboard/Maps/googleMaps.svelte";
import VectorMaps from "./views/Dashboard/Maps/vectorMaps.svelte";
//calendar
import Calendar from "./views/Dashboard/calendar.svelte";

//404 page
import NotFoundPage from "./views/Dashboard/NotFoundPage.svelte";
const routes = [{
        name: '/',
        component: PublicLayout,
        nestedRoutes: [
            { name: 'index', component: PublicIndex }
        ]
    },
    { name: 'login', component: Login, layout: PublicLayout },
    { name: 'lock', component: Lock, layout: PublicLayout },
    { name: 'register', component: Register, layout: PublicLayout },
    { name: 'pricing', component: Pricing, layout: PublicLayout },
    { name: '404', path: '404', component: NotFoundPage, layout: PublicLayout },
    {
        name: 'dashboard',
        component: DashboardLayout,
        nestedRoutes: [
            { name: 'index', component: DashboardIndex },
            { name: 'alternative', component: Alternative },
            { name: 'charts', component: Charts },
            { name: 'widgets', component: Widgets },
            { name: 'pages', component: TimeLinePage },
            { name: 'pages/user', component: UserProfile },
            { name: 'components', component: Buttons },
            { name: 'components/cards', component: Cards },
            { name: 'components/grid-system', component: GridSystem },
            { name: 'components/notifications', component: Notifications },
            { name: 'components/icons', component: Icons },
            { name: 'components/typography', component: Typography },
            { name: 'forms', component: FormElements },
            { name: 'forms/components', component: FormComponents },
            { name: 'forms/validation', component: FormValidation },
            { name: 'tables', component: RegularTables },
            { name: 'tables/sortable', component: SortableTables },
            { name: 'tables/paginated', component: PaginatedTables },
            { name: 'maps', component: GoogleMaps },
            { name: 'maps/vector', component: VectorMaps },
            { name: 'calendar', component: Calendar },
        ]
    }
]

export { routes }