<script>
  let year = new Date().getFullYear();
</script>

<footer class="footer px-4 mx-2">
  <div class="row align-items-center justify-content-lg-between">
    <div class="col-lg-6">
      <div class="copyright text-center text-lg-left text-muted">
        @ {year}
        <a
          href="https://www.creative-tim.com"
          class="font-weight-bold ml-1"
          target="_blank">
          Creative Tim
        </a>
      </div>
    </div>
    <div class="col-lg-6">
      <ul class="justify-content-center nav nav-footer justify-content-lg-end">
        <li class="nav-item">
          <a href="https://www.creative-tim.com" target="_blank" class="nav-link">
            Creative Tim
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.creative-tim.com/presentation" target="_blank" class="nav-link">
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a href="http://blog.creative-tim.com" target="_blank" class="nav-link">
            Blog
          </a>
        </li>
        <li class="nav-item">
          <a href="https://www.creative-tim.com/license" target="_blank" class="nav-link">
            License
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
